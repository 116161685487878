<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else class="d-flex justify-content-center">
      <map-order
        ref="mapOrderId"
        @getOpenMap="getOpenMap"
        @editPerformers="editPerformers"
        @editOrder="editOrder"
        :division="division"
        :orders="orders"
        :orderPerformers="orderPerformers"
        :isActivePerformens="isActivePerformens"
        @getPerformers="getPerformers"
        :iscircleRadius="iscircleRadius"
      ></map-order>
      <map-order-infomation
        :performers="performers"
        :order="order"
        :division="division"
        :orderMap="orderMap"
        @getOpenMap="getOpenMap"
        @getPerformers="getPerformers"
        @getIsActiveFree="getIsActiveFree"
        @getRadiusId="getRadiusId"
      ></map-order-infomation>
    </div>
  </div>
</template>

<script>
import mapOrder from "@/views/component/Modal/ModalOrderMap/mapOrder.vue";
import mapOrderInfomation from "@/views/component/Modal/ModalOrderMap/mapOrderInfomation.vue";
export default {
  components: {
    mapOrder,
    mapOrderInfomation,
  },
  data() {
    return {
      showPreloader: false,
      isActivePerformens: true,
      orders: [],
      orderMap: {},
      order: {},
      performers: {},
      orderPerformers: [],
      iscircleRadius: "",
      division: {
        id: 1,
        lat: "40.28256000",
        lng: "69.62216000",
        name: "Грам (Худжанд)",
        zoom: 14,
      },
      updateMapsIntervalId: null,
    };
  },
  mounted() {
    this.getOrdermap();
    this.getOpenMap();
    this.startUpdateInterval();
  },
  methods: {
    startUpdateInterval() {
      this.updateMapsIntervalId = setInterval(() => {
        this.getOpenMap();
      }, 30000);
    },
    stopUpdateInterval() {
      if (this.updateMapsIntervalId) {
        clearInterval(this.updateMapsIntervalId);
        this.updateMapsIntervalId = null;
      }
    },
    getOpenMap(division) {
      this.division = division ? division : this.division;
      this.updateMaps();
      Promise.all([this.refresh(), this.getPerformers()]);
    },
    refresh() {
      this.$http
        .get(`orders-map/orders?division_id=${this.division.id}`)
        .then((res) => {
          this.orders = res.data;
          if (this.$refs.mapOrderId) {
            this.$refs.mapOrderId.orderIcon(this.orders);
          }
          this.$store.commit("pageData/setdataCount", this.orders.length);
          this.$store.commit("REFRESH_DATA", false);
        });
    },
    getOrdermap() {
      this.$http.get("orders/data").then((res) => {
        this.orderMap = res.data;
      });
    },
    getPerformers() {
      this.$http
        .get(`orders-map/performers?division_id=${this.division.id}`)
        .then((res) => {
          this.orderPerformers = res.data;
          if (this.$refs.mapOrderId) {
            this.$refs.mapOrderId.performerIcon(this.orderPerformers);
          }
        });
    },
    getIsActiveFree(isActivePerformen) {
      this.isActivePerformens = isActivePerformen;
    },
    getRadiusId(isRadius) {
      this.iscircleRadius = isRadius;
    },
    editOrder(order) {
      this.order = order;
    },
    editPerformers(performers) {
      this.performers = performers;
    },
    updateMaps() {
      if (this.$refs.mapOrderId) {
        this.$refs.mapOrderId.updateMap();
      }
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        this.getOpenMap();
      }
    },
  },
  beforeDestroy() {
    this.stopUpdateInterval();
  },
};
</script>
