<template>
  <div id="map" ref="mapContainer"></div>
</template>

<script>
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import settingMap from "@/utils/map/settingMap";
import distanceRoute from "@/utils/distanceRoute/index";
import {
  deleteMapContributors,
  getSvgWithColor,
  svgStringToImageSrc,
} from "@/utils/map/deleteMapContributors";
import { createRoute } from "@/utils/map/createRoute.js";
import { createCircleOrder } from "@/utils/map/createCircle.js";
export default {
  props: [
    "orders",
    "orderPerformers",
    "iscircleRadius",
    "isActivePerformens",
    "division",
  ],
  data() {
    return {
      map: null,
      markerCar: [],
      markerOrder: [],
      text: null,
      orderIdRemove: {
        id: null,
      },
      markerHome: [],
      circleOrder: [],
      performerID: {
        id: null,
      },
    };
  },
  mounted() {
    this.orderMap();
    deleteMapContributors();
  },
  methods: {
    orderMap() {
      this.map = new maplibregl.Map({
        container: this.$refs.mapContainer,
        style: settingMap.map.style,
        zoom: settingMap.map.zoom,
        maxZoom: settingMap.map.maxZoom,
        minZoom: settingMap.map.minZoom,
        center: [this.division.lng, this.division.lat],
      });
      this.map.on("load", () => {
        this.map.addSource("map", {
          type: "vector",
          tiles: ["https://mtile.gram.tj/data/v3/{z}/{x}/{y}.pbf"],
          cluster: true,
        });
      });
      this.map.dragRotate.disable();
      this.map.keyboard.disable();
      this.map.touchZoomRotate.disableRotation();
    },
    updateMap() {
      this.map.getLayer("route") || this.map.getSource("route")
        ? ""
        : this.clear();
    },
    performerIcon(orderPerformers) {
      if (this.markerCar.length) this.markerCar.forEach((e) => e.remove());
      this.markerCar = [];
      for (let i = 0; i < orderPerformers.length; i++) {
        if (orderPerformers[i].free == 0 && this.$store.state.map.is_free) {
          orderPerformers[i].color = "cornsilk";
        } else if (orderPerformers[i].color == "cornsilk") {
          orderPerformers[i].color = "#94AEC7";
        }
      }
      this.createMarkerPerformer(orderPerformers);
    },
    createMarkerPerformer(orderPerformers) {
      orderPerformers.forEach((e) => {
        const svgString = getSvgWithColor(e.color, "performer", e.direction);
        const svgImage = new Image();
        svgImage.src = svgStringToImageSrc(svgString);
        svgImage.style.width = "40px";
        svgImage.style.height = "40px";
        svgImage.style.cursor = "pointer";

        svgImage.onload = () => {
          const imageName = `custom-svg-performer${e.id}`;
          if (this.map.hasImage(imageName)) {
            this.map.removeImage(imageName);
          }
          this.map.addImage(imageName, svgImage);
          const markerElement = document.createElement("div");
          markerElement.appendChild(svgImage);
          markerElement.dataset.orderID = e.id;

          const markerCar = new maplibregl.Marker({
            element: markerElement,
          })
            .setLngLat([e.lng, e.lat])
            .addTo(this.map);

          this.markerCar.push(markerCar);

          markerElement.addEventListener("click", () => {
            this.checkPerformerID(e);
          });
        };
      });
    },
    checkPerformerID(e) {
      if (this.performerID?.id !== null) {
        if (this.performerID?.id !== e.id) {
          this.$store.commit("map/SETISACTIVEPERFORMER", true);
          this.clearPerformer(this.performerID);
          this.$store.commit("map/SETISACTIVEPERFORMER", false);
          this.routerPerformer(e);
          return;
        }
      }

      if (this.$store.state.map.is_activePerformer) {
        this.$store.commit("map/SETISACTIVEPERFORMER", false);
        this.routerPerformer(e);
      } else {
        this.$store.commit("map/SETISACTIVEPERFORMER", true);
        this.clearPerformer(e);
      }
    },
    routerPerformer(e) {
      this.$emit("editPerformers", e);
      this.performerID = {
        id: e.id,
      };
      const orderLength = this.$store.state.map.dataOrder.length > 0;
      const orderID = this.orders.some(
        (order) => order.id === this.orderIdRemove.id
      );

      if (orderLength && orderID) {
        this.conditions(e);
      } else {
        this.clearOrder();
      }
    },
    clearOrder() {
      this.$store.commit("map/ORDERDATA", []);
      this.orderIdRemove = {
        id: null,
      };
    },
    conditions(e) {
      this.$store.commit("map/PERFORMERDATA", e);
      let points = [
        {
          lng: e.lng,
          lat: e.lat,
        },
        {
          lng: this.$store.state.map.dataOrder[0].from_address.lng,
          lat: this.$store.state.map.dataOrder[0].from_address.lat,
        },
      ];
      distanceRoute(points, this)
        .then((res) => {
          if (res.data && e.id) {
            createRoute(res.data.route, e.id.toString(), this.map);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      this.$emit("editPerformers", e);
      this.performerID = e;
    },
    clearPerformer(e) {
      this.$emit("editPerformers", "");
      this.clear(e, "performer");
      this.performerID = {
        id: null,
      };
      this.$emit("getOpenMap");
    },
    orderIcon(orders) {
      orders.forEach((e) => {
        const svgString = getSvgWithColor(e.color, "order");
        const svgImage = new Image();
        svgImage.src = svgStringToImageSrc(svgString);
        svgImage.style.cursor = "pointer";
        svgImage.style.width = "25px";
        svgImage.style.height = "25px";

        svgImage.onload = () => {
          const imageName = `custom-svg-order${e.id}`;
          if (this.map.hasImage(imageName)) {
            this.map.removeImage(imageName);
          }
          this.map.addImage(imageName, svgImage);

          const markerElement = document.createElement("div");
          markerElement.appendChild(svgImage);
          markerElement.dataset.orderID = e.id;

          const markerOrder = new maplibregl.Marker({
            element: markerElement,
          })
            .setLngLat([e.lng, e.lat])
            .addTo(this.map);

          this.markerOrder.push(markerOrder);

          markerElement.addEventListener("click", () => {
            if (this.$store.state.map.is_activeOrder) {
              this.$store.commit("map/SETISACTIVEORDER", false);
              this.createOrderRoute(e);
              if (this.performerID.id) {
                this.$store.commit("map/SETISACTIVEPERFORMER", true);
                this.clearPerformer(this.performerID);
              }
            } else {
              this.$store.commit("map/SETISACTIVEORDER", true);
              this.clearOrder(e);
              if (this.performerID.id) {
                this.$store.commit("map/SETISACTIVEPERFORMER", true);
                this.clearPerformer(this.performerID);
              }
            }
          });
        };
      });
    },
    clearOrder(e) {
      this.$emit("editOrder", "");
      this.clear(e);
      this.$store.commit("map/SETDATAORDER", []);
      this.clearOrderHome();
      this.clearRadius();
      this.$emit("getOpenMap");
    },
    async createOrderRoute(e) {
      this.$store.commit("map/ORDERDATA", e);
      this.orderIdRemove = e;
      const editOrderDataGeoJsonArray = await this.editOrderDataGeoJsonArray(e);
      this.$store.commit("map/SETDATAPUSH", editOrderDataGeoJsonArray);
      let points = [
        {
          lng: e.lng,
          lat: e.lat,
        },
      ];
      this.$emit("editOrder", e);
      this.circleOrder = createCircleOrder(
        e,
        this.$store.state.map.circleRadius,
        this.map
      );
      if (this.$store.state.map.dataOrder[0].to_addresses.length > 0) {
        this.$store.state.map.dataOrder[0].to_addresses.forEach((order) => {
          points.push({ lng: order.lng, lat: order.lat });
        });
        distanceRoute(points, this)
          .then((res) => {
            createRoute(res.data.route, e.id.toString(), this.map);
            this.createMarkerOrderHome(this.$store.state.map.dataOrder[0]);
            this.editColorOrder(this.$store.state.map.dataOrder[0]);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    editColorOrder(order) {
      this.orders.forEach((e) => {
        if (order.id === e.id) {
          this.orders.color = "green";
        } else {
          this.orders.color = "red";
        }
      });
    },
    createMarkerOrderHome(data) {
      data.to_addresses.forEach((e) => {
        const el = document.createElement("div");
        el.style.width = "56px";
        el.style.height = "56px";
        el.style.backgroundImage = `url(${settingMap.map.home})`;
        el.style.backgroundSize = "cover";
        let markerHome = new maplibregl.Marker({ element: el })
          .setLngLat([e.lng, e.lat])
          .addTo(this.map);
        this.markerHome.push(markerHome);
      });
    },
    editOrderDataGeoJsonArray(e) {
      return this.$http.get(`orders-map/orders/${e.id}/show`).then((res) => {
        return res.data;
      });
    },
    clearOrderHome() {
      if (this.markerHome) this.markerHome.forEach((e) => e.remove());
    },
    clearRadiusOrder() {
      if (
        this.map.getLayer("location-radius") &&
        this.map.getLayer("location-radius-outline")
      ) {
        this.map.removeLayer("location-radius");
        this.map.removeLayer("location-radius-outline");
        this.map.removeSource("location-radius");
        this.map.removeSource("location-radius-outline");
      }
    },
    clear(e, param) {
      if (e && e.id) {
        const text = e.id.toString();
        const routeId = `route-${text}`;
        const route2Id = `route2-${text}`;
        if (this.map.getLayer(routeId)) {
          this.map.removeLayer(routeId);
        }
        if (this.map.getLayer(route2Id)) {
          this.map.removeLayer(route2Id);
        }

        if (this.map.getSource(routeId)) {
          this.map.removeSource(routeId);
        }
      }
      if (param !== "performer") {
        if (this.markerOrder.length)
          this.markerOrder.forEach((e) => e.remove());
        if (this.markerCar.length) this.markerCar.forEach((e) => e.remove());
        this.markerCar = [];
        this.markerOrder = [];
        this.circleOrder = [];
      }
    },
    clearRadius() {
      this.clearRadiusOrder();
    },
  },
  created() {
    this.$root.$on("callClearClickInOtherComponent", () => {
      this.clear(this.$store.state.map.orderData);
      this.clear(this.$store.state.map.performerData);

      if (this.orderIdRemove.id) {
        this.clearRadius();
        this.clearOrderHome();
        // this.clearPerformer(this.performerID)
        // this.clearOrder(this.orderIdRemove)
      }
    });
  },
  watch: {
    division(newValue) {
      this.map.jumpTo({
        center: [newValue.lng, newValue.lat],
        zoom: settingMap.map.zoom,
      });
      this.clear(this.performerID);
      this.clear(this.$store.state.map.orderData);
      this.clearOrderHome();
      this.clearRadius();
    },
    isActivePerformens(newValue) {
      this.$store.commit("map/SETISFREE", newValue);
      if (newValue == true) {
        this.performerIcon(this.orderPerformers);
      } else {
        this.performerIcon(this.orderPerformers);
      }
    },
    iscircleRadius(newValue) {
      this.$store.commit("map/SETCIRCLE_RADIUS", newValue);
      this.clearRadius();
      this.circleOrder = createCircleOrder(
        this.$store.state.map.orderData,
        this.$store.state.map.circleRadius,
        this.map
      );
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  },
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: calc(100vh - 93px) !important;
}
</style>
